import { defineStore } from 'pinia';
import { useAppStore, useSessionStore, useAnalyticsStore } from '@/store';
import { useForm } from 'vee-validate';
import router from '../router';
import sanctum from '@/api/auth/sanctum';
import { setUser } from '@/plugins/sentry';
import { notify } from '@kyvg/vue3-notification';

export const useFormStore = defineStore('form', () => {
    // Stores
    const app = useAppStore();
    const session = useSessionStore();
    const analytics = useAnalyticsStore();

    // State
    const loading = ref(false);
    const errors = ref(null);
    const formBinding = ref({
        service_type: 'MOVE',
        move: {},
        customer: {},
        order: {},
        feedback: {},
    });

    const currentForm = ref(null);

    const isValidForm = computed(() => {
        if (currentForm.value) {
            return currentForm.value.meta.valid;
        } else {
            return false;
        }
    });

    const isSubmittable = computed(() => {
        return (
            (isValidForm.value && currentForm.value.autoNext && currentForm.value.meta.dirty) ||
            (isValidForm.value && currentForm.value.autoNext && currentForm.value.meta.touched)
        );
    });

    watch(isSubmittable, async (newVal) => {
        if (isValidForm.value && currentForm.value.meta.dirty) {
            await currentForm.value.validate();
        }
        if (newVal && isValidForm.value) {
            submit();
        }
    });

    const isLoading = computed(() => {
        return loading.value;
    });

    function init({ validationSchema = null, initialValues = null, autoNext = false, nextRoute = null }) {
        console.log('Init form', initialValues);

        currentForm.value = useForm({
            validationSchema: validationSchema,
            initialValues: initialValues,
        });

        console.log('Current form', currentForm.value);

        // Handle autoNext
        if (!autoNext) {
            currentForm.value.autoNext = false;
        } else {
            currentForm.value.autoNext = true;
        }

        // Handle nextRoute
        if (!nextRoute) {
            console.error(
                'No next route provided. This will prevent form submission from navigating to the next step.',
            );
            currentForm.value.nextRoute = null;
        } else {
            currentForm.value.nextRoute = nextRoute;
        }
    }

    function setFormBinding(formValues) {
        formBinding.value = {
            service_type: 'MOVE',
            form_step: router.currentRoute.value.path,
            move: {
                ...formValues.move,
                form_step: router.currentRoute.value.path,
            },
            customer: {
                ...formValues.customer,
            },
            order: {
                ...formValues.order,
            },
            feedback: {
                ...formValues.feedback,
            },
        };
    }

    /*
     * Form submission handler
     * Handles step submission, error handling and subsequent form navigation
     */
    async function submit(values = null) {
        if (isLoading.value) {
            return;
        }

        if (!isValidForm.value) {
            await currentForm.value.validate();

            if (!isValidForm.value) {
                console.log(`form errors:`, currentForm.value.errors);
                return;
            }
        }

        const _values = values ? values : currentForm.value.values;

        console.log('Submitting form', _values);

        loading.value = true;

        setFormBinding({
            move: {
                booking_type: session.getBookingType,
                ..._values.move,
            },
            customer: {
                ..._values.customer,
            },
            order: {
                ..._values.order,
            },
            feedback: {
                ..._values.feedback,
            },
        });

        try {
            // Check if we have a session
            if (session.hasSession) {
                await handleUpdate(_values);
            } else {
                await handleCreate();
            }
        } catch (error) {
            app.loading = false;
            console.error('Form submission error', error);

            if (currentForm.value && error.errors) {
                currentForm.value.setErrors(error.errors);
                errors.value = error.errors;
                error.value = true;
            } else {
                console.error('No form initialized');
            }
        } finally {
            loading.value = false;
        }
    }

    /*
     * Create new order
     */
    async function handleCreate() {
        const session = useSessionStore();

        // get csrf beforehand
        await sanctum.fetchCSRFToken();

        const { data } = await window.axios.post('/customers/book', {
            form_step: router.currentRoute.value.path,
            ...formBinding.value,
            customer: {
                ...formBinding.value.customer,
            },
            order: {
                ...formBinding.value.order,
            },
        });

        if (data.order) {
            // Init the session we just created
            await session.init({
                order: data.order,
                orderRef: data.order.ref,
                token: data.order.customer.referral_token,
                isCreate: true,
            });

            // set the orderRef in sentry do this here so it's only done once
            setUser({ orderRef: session.getOrderRef });

            analytics.bookStart();
            router.push({ path: currentForm.value.nextRoute });
        }
    }

    /*
     * Handle update of form
     */
    async function handleUpdate(values) {
        const connectionRequestStatus =
            currentForm.value?.values?.connection_request_status == 'NO_RESPONSE' ?
                null
            :   currentForm.value?.values?.connection_request_status;

        console.log('Session update handle...', values);

        const formData = {
            service_type: 'MOVE',
            form_step: router.currentRoute.value.path,
            move: {
                form_step: router.currentRoute.value.path,
                ...(values.move || {}),
            },
            customer: {
                ...values.customer,
            },
            order: {
                ...values.order,
            },
            feedback: {
                ...values.feedback,
            },
            connection_request_status: connectionRequestStatus,
            connection_callback_date: values.connection_callback_date,
            connection_partner: values.connection_partner,
        };

        let response = null;

        if (router.currentRoute.value.path === '/inventory') {
            // map out the inventory items to only include the id and quantity
            if (formData.move.inventory) {
                formData.move.inventory = getFormattedInventoryItems(formData.move.inventory);
            }
        }

        // Handle inventory add
        if (router.currentRoute.value.path === '/add/inventory') {
            const isCreate = !session.hasInventory;

            if (isCreate) {
                // is create
                response = await window.axios.post(`/customers/book/orders/${session.order.id}/inventory`, {
                    service_type: 'MOVE',
                    move: {
                        inventory: getFormattedInventoryItems(formData.move.inventory),
                    },
                });
            } else {
                // is update
                response = await window.axios.put(`/customers/book/orders/${session.order.id}/inventory`, {
                    service_type: 'MOVE',
                    move: {
                        inventory: getFormattedInventoryItems(formData.move.inventory),
                    },
                });
            }

            await session.retrieve();
        } else {
            response = await window.axios.patch(`/customers/book/${session.orderRef}`, {
                ...formData,
                move: {
                    // This is needed because vee-validate automatically cleans up any empty objects and arrays along a value path: https://github.com/logaretm/vee-validate/issues/3611
                    pickup_access: [],
                    delivery_access: [],
                    ...formData.move,
                    quote_type: formData.move.quote_type,
                    inventory: formData.move.inventory,
                },
            });

            if (session.orderRef) {
                if (response.data?.order?.ref && session.orderRef === response.data?.order?.ref) {
                    console.log('✅ Order ref match', session.orderRef, response.data?.order?.ref);
                } else {
                    console.error(
                        '⛔️ Order ref mismatch - possible network issue',
                        session.orderRef,
                        response.data?.order?.ref,
                    );

                    notify({
                        group: 'app_error',
                        title: 'Please try again',
                        text: 'Unexpected error occurred when attempting to save your data',
                    });

                    return;
                }
            }
        }

        // if we're on the customer details page, fire the book lead event
        if (router.currentRoute.value.path === '/step/customer_details') {
            if (
                response?.data?.order?.connection_request_status == 'CALLBACK' || // legacy - keep since some customers may have this status when booking
                response?.data?.order?.connection_request_status == 'SCHEDULED_CALLBACK' ||
                response?.data?.order?.connection_request_status == 'CONTACT_THIS_WEEK' || // legacy - keep since some customers may have this status when booking
                response?.data?.order?.connection_request_status == 'CONTACT_ASAP' || // legacy - keep since some customers may have this status when booking
                response?.data?.order?.connection_request_status == 'CONTACT_NEXT_WEEK' || // legacy - keep since some customers may have this status when booking
                response?.data?.order?.connection_request_status == 'CONTACT_REMIND_LATER' || // legacy - keep since some customers may have this status when booking
                response?.data?.order?.connection_request_status == 'CONTACT_TOMORROW_DELAYED' ||
                response?.data?.order?.connection_request_status == 'CONTACT_IN_A_FEW_DAYS_DELAYED' ||
                response?.data?.order?.connection_request_status == 'CONTACT_NEXT_WEEK_DELAYED' ||
                response?.data?.order?.connection_request_status == 'CONTACT_IN_TWO_WEEKS_DELAYED'
            ) {
                analytics.bookLeadWithEnergy();
            }

            // always fire the book lead event
            analytics.bookLead();
        }

        const data = response.data;

        session.setSessionState({
            ...optionalSpread('order', data?.order),
            ...optionalSpread('orderRef', data?.order?.ref),
            ...optionalSpread('token', data?.order?.customer?.referral_token),
            ...optionalSpread('bookingType', data?.order?.primary_item?.product?.booking_type),
        });

        if (data.inventory) {
            session.setSessionState({
                inventory: data.inventory,
            });
        }

        if (currentForm.value.nextRoute === '/results/loading') {
            router.replace({ path: currentForm.value.nextRoute });
        } else {
            router.push({ path: currentForm.value.nextRoute });
        }
    }

    // Helper function to return an object with a key/value pair if the value is truthy or an empty object if not
    function optionalSpread(key, value) {
        return value ? { [key]: value } : {};
    }

    function getFormattedInventoryItems(inventory) {
        return inventory.map((item) => {
            return {
                inventory_option_id: item.inventory_option_id,
                qty: item.qty,
            };
        });
    }

    return {
        init,
        loading,
        submit,
        currentForm,
        isValidForm,
        isSubmittable,
        formBinding,
    };
});
